<template>
  <div class="gh-100">
    <div class="g-flex g-wrap">
      <div class="gw-300p gmr-20 gmb-20 gbr-4 gb-888 gc-fff gpv-10 gph-10" v-for="(item,index) in list" :key="index">
        <div class="g-flex g-justify-space-between g-align-center gmb-10">
          <div>
            <span class="gfs-20 gmr-15">{{item.name}}</span>
            <span @click="changeCollectStatus(item,1)" class="iconfont icon-noCollect g-pointer" style="font-size:18px" v-if="!item.status"></span>
            <span @click="changeCollectStatus(item,0)" class="iconfont icon-collect gc-red g-pointer" style="font-size:18px" v-else></span>
          </div>
        </div>
        <div class="g-flex g-align-center gmb-10">
          <span class="gmr-5">{{item.e_pronu}}</span>
          <span class="iconfont icon-voice gmr-15 g-pointer" @click="playAudio(item.e_voice)"></span>
          <span class="gmr-5">{{item.a_pronu}}</span>
          <span class="iconfont icon-voice g-pointer" @click="playAudio(item.a_voice)"></span>
        </div>
        <div>
          <div v-for="(child,childIdx) in item.word_explain_list" :key="childIdx" :class="{'gmb-10':childIdx!=item.word_explain_list.length-1}">
            <span>{{child.word_type}}</span>
            <span>{{child.word_translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <audio :src="curAudio" id="audioMusic"></audio>
    <div class="gps-fix" style="bottom:80px;right:30px">
      <a-pagination v-model="param.page" :total="total" show-less-items @change="changeTable" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getCollectWordList, changeCollect } from "@/api/wordLib";
import { getAudioUrl } from "@/utils/common";
export default {
  data() {
    return {
      param: {
        page: 1,
        limit: 10,
      },
      total: 0,
      list: [],
      curAudio: "",
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain"]),
  },
  created() {
    this.getData();
  },
  methods: {
    //播放
    playAudio(src) {
      this.curAudio = getAudioUrl(src);
      setTimeout(() => {
        audioMusic.play();
      });
    },
    //收藏
    changeCollectStatus(item, status) {
      item.status = status;
      let params = {
        id: item.id,
        is_collect: item.status,
        word: item.name,
      };
      changeCollect(params);
    },
    changeTable() {
      this.getData();
    },
    getData() {
      getCollectWordList(this.param).then((res) => {
        if (res.code == 200) {
          this.total = res.total;
          this.list = res.data.map((item) => {
            return {
              ...item,
              status: 1,
            };
          });
        }
      });
    },
  },
};
</script>