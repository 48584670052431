import axios from '@/utils/request'
/**
 * 词库分页查询
 * @param {Object} data 查询参数
 * @returns {Promise} 返回查询结果的Promise对象
 */
export function getWordInfoPage(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/word-list',
    params: data,
    method: 'get'
  })
}

/**
 * 词库收藏操作
 * @param {Object} data 包含收藏操作所需数据
 * @returns {Promise} 返回操作结果的Promise对象
 */
export function changeCollect(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/collect',
    method: 'put',
    data: data
  })
}

/**
 * 获取收藏单词列表
 * @param {Object} data 查询参数
 * @returns {Promise} 返回查询结果的Promise对象
 */
export function getCollectWordList(data) {
  return axios({
    url: '../word/api/v1/student-collect/word-list',
    method: 'get',
    params: data
  })
}

/**
 * 获取词库分类下拉列表
 * @param {Object} data 查询参数
 * @returns {Promise} 返回查询结果的Promise对象
 */
export function getWordSortList(data) {
  return axios({
    url: '../word/api/v1/word-classify/select-list',
    method: 'get',
    params: data
  })
}

/**
 * 获取词法下拉列表
 * @param {Object} data 查询参数
 * @returns {Promise} 返回查询结果的Promise对象
 */
export function getWordWayList(data) {
  return axios({
    url: '../word/api/v1/word-grammar/select-list',
    method: 'get',
    params: data
  })
}

/**
 * 获取词汇试卷详情
 * @param {String} id 试卷ID
 * @returns {Promise} 返回试卷详情的Promise对象
 */
export function getWordPaperDetail(id) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/log-detail/' + id,
    method: 'get'
  })
}

/**
 * 获取单词详情
 * @param {String} id 单词ID
 * @returns {Promise} 返回单词详情的Promise对象
 */
export function getWordInfo(id) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/detail/' + id,
    method: 'get'
  })
}