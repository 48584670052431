<template>
  <div>
    <a-radio-group button-style="solid" v-model="curTab">
      <a-radio-button :value="1">词汇收藏</a-radio-button>
      <a-radio-button :value="2">机经收藏</a-radio-button>
    </a-radio-group>
    <div class="gph-10 gpv-15 gb-fff">
      <wordCollect v-if="curTab==1"></wordCollect>
      <articleCollect v-if="curTab==2"></articleCollect>
    </div>
  </div>
</template>
<script>
import wordCollect from './wordCollect'
import articleCollect from './articleCollect'
export default {
  components: { wordCollect, articleCollect },
  data() {
    return {
      curTab: 1,
    }
  },
  methods: {},
}
</script>