<template>
  <div class="gh-100">
    <div class="g-flex g-wrap">
      <div class="gw-400p gmr-20 gmb-20 gbr-4 gbd gpv-10 gph-10 g-pointer" v-for="(item,index) in list" :key="index" @click="toDetail(item)">
        <div class="g-flex g-justify-space-between gmb-10">
          <div>
            <span>{{subjectList[item.subject_type-1]}}</span>
            <span>·{{item.question_type_name}}</span>
            <span>·{{item.classify_name}}</span>
          </div>
          <div class="gc-aaa">{{item.update_date && item.update_date.slice(0,10)}}</div>
        </div>
        <div class="g-flex g-justify-space-between">
          <div class="g-flex-1 g-flex g-flex-column g-justify-space-between gmr-10">
            <div class="gfw-b gfs-18">{{item.name}}</div>
            <span @click.stop="changeCollectStatus(item,1)" class="iconfont icon-noCollect g-pointer" style="font-size:18px" v-if="!item.is_collect"></span>
            <span @click.stop="changeCollectStatus(item,0)" class="iconfont icon-collect gc-red g-pointer" style="font-size:18px" v-else></span>
          </div>
          <img v-if="item.cover_img" :src="qiniuDomain+item.cover_img" class="gw-120p gh-120p">
          <img v-else src="@/assets/imgs/img4.png" class="gw-120p gh-120p">
        </div>
      </div>
    </div>
    <div class="gps-fix" style="bottom:80px;right:30px">
      <a-pagination v-model="param.page" :total="total" show-less-items @change="changeTable" />
    </div>
  </div>
</template>
<script>
import { subjectList } from "@/utils/common";
import { mapGetters } from "vuex";
import { getCollectArticleList, putCollectArticle } from "@/api/articlePaper";
export default {
  data() {
    return {
      subjectList: JSON.parse(JSON.stringify(subjectList)),
      param: {
        page: 1,
        limit: 10,
      },
      total: 0,
      list: [],
      curAudio: "",
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain"]),
  },
  created() {
    this.getData();
  },
  methods: {
    //收藏
    changeCollectStatus(item, is_collect) {
      item.is_collect = is_collect;
      let params = {
        important_word_id: item.id,
        is_collect: item.is_collect,
      };
      putCollectArticle(params);
    },
    changeTable() {
      this.getData();
    },
    getData() {
      getCollectArticleList(this.param).then((res) => {
        if (res.code == 200) {
          this.total = res.total;
          this.list = res.data.map((item) => {
            return {
              ...item,
              is_collect: 1,
            };
          });
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/article/detail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>