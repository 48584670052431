import axios from '@/utils/request'

/**
 * 获取预测分页列表
 * @param data 请求参数
 * @returns 返回预测分页列表的数据
 */
export function getPredictPage(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/list',
    data: data,
    method: 'post'
  })
}

/**
 * 获取预测统计详情
 * @param data 请求参数
 * @returns 返回预测统计详情的数据
 */
export function getStatis(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/statis-detail',
    params: data,
    method: 'get'
  })
}

/**
 * 机经单词试卷重置
 * @param data 重置的试卷ID等信息
 * @returns 返回重置操作的结果
 */
export function reset(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/reset/' + data,
    method: 'put',
  })
}

/**
 * 学生预测做机经分页列表
 * @param data 请求参数
 * @returns 返回学生预测做机经分页列表的数据
 */
export function postImportantPage(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/important-word-list',
    data: data,
    method: 'post'
  })
}

/**
 * 获取机经详情
 * @param data 请求参数，包含机经的ID
 * @returns 返回机经详情的数据
 */
export function getImportantDetail(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/important-word-detail/' + data.id,
    params: data,
    method: 'get'
  })
}

/**
 * 获取试卷机经题型下拉列表
 * @param data 请求参数
 * @returns 返回试卷机经题型的下拉列表数据
 */
export function getArticlePaperTypeList(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/question-type/select-list',
    params: data,
    method: 'get'
  })
}

/**
 * 获取试卷机经分类下拉列表
 * @param data 请求参数
 * @returns 返回试卷机经分类的下拉列表数据
 */
export function getArticlePaperSortList(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/classify/select-list',
    params: data,
    method: 'get'
  })
}

/**
 * 获取学生机经预测统计详情
 * @param id 机经的ID
 * @returns 返回学生机经预测统计详情的数据
 */
export function getArticleDetail(id) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/important-word-paper/detail/' + id,
    method: 'get'
  })
}

/**
 * 获取学生练习机经列表
 * @param id 学生的ID
 * @returns 返回学生练习机经的列表数据
 */
export function getStudyArticleList(id) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log/important-word-ids/' + id,
    method: 'get'
  })
}

/**
 * 预测机经提交答案
 * @param data 包含答案的数据
 * @returns 返回提交答案的结果数据
 */
export function postArticlePaperAnswer(data) {
  return axios({
    url: '../word/api/v1/student-important-word-paper-log',
    method: 'post',
    data
  })
}

/**
 * 机经收藏
 * @param data 包含收藏信息的数据
 * @returns 返回收藏操作的结果数据
 */
export function putCollectArticle(data) {
  return axios({
    url: '../word/api/v1/student-collect/important-word-collect',
    method: 'put',
    data
  })
}

/**
 * 获取机经收藏列表
 * @param data 请求参数，可能包含分页信息等
 * @returns 返回机经收藏的列表数据
 */
export function getCollectArticleList(data) {
  return axios({
    url: '../word/api/v1/student-collect/important-word-list',
    method: 'get',
    params: data
  })
}

/**
 * 获取机经题型下拉列表
 * @param data 请求参数
 * @returns 返回机经题型的下拉列表数据
 */
export function getArticleTypeList(data) {
  return axios({
    url: '../word/api/v1/question-type/select-list',
    method: 'get',
    params: data
  })
}

/**
 * 获取机经分类下拉列表
 * @param data 请求参数
 * @returns 返回机经分类的下拉列表数据
 */
export function getArticleSortList(data) {
  return axios({
    url: '../word/api/v1/classify-info/select-list',
    method: 'get',
    params: data
  })
}